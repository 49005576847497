import { CheckCircleIcon } from '@heroicons/react/outline';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React from 'react';
import Blurb from '~components/common/Blurb';
import Button from '~components/common/Button';
import Container from '~components/common/Container';
import CourseButton from '~components/common/CourseButton';
import Disclosure from '~components/common/Disclosure';
import FaqItem from '~components/common/FaqItem';
import FeatureList from '~components/common/FeatureList';
import GallerySlider from '~components/common/GallerySlider';
import GridThree from '~components/common/GridThree';
import GridTwo from '~components/common/GridTwo';
import ImageVideo from '~components/common/ImageVideo';
import TestmonialsSlider from '~components/common/TestmonialsSlider';
import Layout from '~components/layouts/Default';
import Cta from '~components/sections/Cta';
import PageHeader from '~components/sections/PageHeader';
import H2 from '~components/typography/H2';
import P from '~components/typography/P';

function Page({ location, data }) {
  const features = [];
  data.kurse.features.forEach((feature) => {
    features.push({
      gray: feature.field.label === 'Ausgegraut (nicht verfügbar)' ? true : false,
      text: feature.value
    });
  });

  const faq = [
    {
      text: '<p>Durch den Wechsel von An- und Entspannung erlernt Dein K&ouml;rper, sich auch in Stressmomenten zu entspannen. Das tr&auml;gt wesentlich zu deiner Gesundheit bei. Die Progressive Muskelentspannung wurde von Edmund Jacobsen erfunden und wurde seither immer weiter erforscht. Es ist neben dem Autogenen Training ein sehr wissenschaftliches Entspannungsverfahren, das mit einer klassischen Konditionierung arbeitet. Die Zust&auml;nde von Anspannung und Entspannung werden an die Atmung und aneinander gekoppelt, so dass dein K&ouml;rper lernt, in Momenten der Anspannung, automatisch zu entspannen.</p>',
      id: '6077ec01922e814b790eac41',
      frage: 'Was ist Progressive Muskelentspannung?'
    },
    {
      text: '<p>Das Autogene Training ist eines der zwei gro&szlig;en Entspannungsverfahren, die in Westeuropa am weitesten erforscht sind. Die positiven Auswirkungen sind wissenschaftlich belegt. Das andere Verfahren ist die Progressive Muskelentspannung. Auch mit dieser Methode machen &Auml;rzte, Psycho- aber auch Physiotherapeuten gute Fortschritte in der Arbeit mit ihren Patienten. Die Wirksamkeit ist daher unbestritten.&nbsp;</p>',
      id: '6077ec2083750949b741fc61',
      frage: 'Bringen solche Verfahren wirklich was?'
    },
    {
      text: '<p>Die beste Wirkung wird nat&uuml;rlich mit dem regelm&auml;&szlig;igen &uuml;ben eintreten. Es empfiehlt sich immer wieder dieselbe Zeit und denselben Ort einzunehmen. Wir empfehlen, zwei bis dreimal pro Woche zu &uuml;ben. Allerdings ist es auch nicht schlimm, wenn du es mal nicht schaffst zu trainieren. Daf&uuml;r hast du schlie&szlig;lich die begleiteten Kursstunden. Einmal richtig erlernt, kannst du es dein ganzes Leben lang nutzen.</p>',
      id: '6077ec2b922e814b790eac43',
      frage: 'Wie lange muss ich pro Tag üben?'
    },
    {
      text: '<p>Die Kurse Progressive Muskelentspannung &amp; Autogenes Training sind nach &sect;20 SGB V von der Zentralen Pr&uuml;fstelle der Pr&auml;vention zertifiziert und werden von den gesetzlichen Krankenkassen bezuschusst.</p>\n<p>Kurs-<strong>ID 20200807-1221034</strong>&nbsp;(Onlinekurs Autogenes Training) | Zentrale Pr&uuml;fstelle Pr&auml;vention.<br />Kurs-<strong>ID 20200625-12</strong><strong>9838</strong>&nbsp;(Onlinekurs Progressive Muskelentspannung) | Zentrale Pr&uuml;fstelle Pr&auml;vention.</p>',
      id: '6077ec468be6012a8800aef1',
      frage: 'Bezuschussen die Krankenkassen die Kurse?'
    },
    {
      text: '<p>Nein! Du kannst die Kurse buchen und die Teilnahmebescheinigung nach Abschluss bei der Krankenkasse einreichen. Die Bezuschussung von ca. 75&euro; &ndash; 150&euro; ist in der Regel innerhalb von 14 Tagen nach Abschluss des Kurses auf deinem Konto.</p>',
      id: '6077ec53afc98102f2507eb1',
      frage: 'Muss ich die Kurse vor Buchung bei der Krankenkasse anfragen?'
    },
    {
      text: '<p>Die Bezuschussung betr&auml;gt zwischen ca. 75&euro; &ndash; 150&euro; der Kosten. F&uuml;r eine genaue Aussage kontaktiere bitte deine Krankenkasse, da es hier individuelle Unterschiede gibt.</p>',
      id: '6077ec5d8be6012a8800aef3',
      frage: 'Wie hoch fällt die Krankenkassenförderung aus?'
    },
    {
      text: '<p>Kein Stress! Du kannst deinen Kurs insgesamt in Summe 4 Wochen aufschieben.</p>',
      id: '6077ec688be6012a8800aef5',
      frage: 'Was passiert , wenn ich krank oder im Urlaub bin während des Kurses?'
    },
    {
      text: '<p>Du kannst die Kurse so oft buchen wie du m&ouml;chtest. Eine Krankenkassenf&ouml;rderung steht dir jedoch nur f&uuml;r zwei Kurse pro Kalenderjahr zur Verf&uuml;gung.</p>',
      id: '6077ec718be6012a8800aef7',
      frage: 'Wie viele Kurse darf ich Buchen?'
    }
  ];

  return (
    <Layout location={location} title="Progressive Muskelentspannung ► Onlinekurs" desc="Progressive Muskelentspannung ★ der zertifizierte Onlinekurs der Entspannungshelden hilft auch dir gegen nächtliches Knirschen & Verspannungen" green>
      <PageHeader title={data.kurse.title} image={data.bildKurs} classNameImage="animate-shake-vertical" wrapperClass="max-w-5xl mx-auto">
        {data.kurse.desc && <P>{data.kurse.desc}</P>}
        <p className="font-display">
          <span className="text-5xl">{data.kurse.price} €</span>
          {/* space */}
          <span className="text-base"> /{data.kurse.frequenz} inkl USt.</span>
        </p>
        <div className="py-8">
          <h3 as="p" className="mb-3 font-display font-medium">
            Beinhaltet:
          </h3>
          <FeatureList items={features} className="space-y-5" />
        </div>
        <CourseButton buttonText={`${data.kurse.title} buchen`} modalButtonText={`Bestätigen`} dialogTitle="Kontraindikationen" link={data.kurse.link} />
      </PageHeader>

      <Container>
        <H2 className="mb-16 text-center font-display">Warum du die progressive Muskelentspannung lieben wirst:</H2>
        <dl>
          <GridThree>
            <Blurb name="Förderliche Effekte für deine Gesundheit" description="Neben der Muskelentspannung gibt es viele weitere förderliche Effekte." icon={<CheckCircleIcon className="h-12 w-12" />} />
            <Blurb name="Schmerzreduktion" description="Reduktion von Rücken- und anderen Muskelschmerzen." icon={<CheckCircleIcon className="h-12 w-12" />} />
            <Blurb name="Einfach zu erlernen" description="Das aktive Entspannungsverfahren ist sehr einfach zu erlernen." icon={<CheckCircleIcon className="h-12 w-12" />} />
          </GridThree>
        </dl>
      </Container>
      <Container noMargin className="text-brand-gray">
        <GridTwo>
          <div>
            <H2 className="mb-5 font-display">Entspannung für deine Muskulatur: Progressive Muskelentspannung Onlinekurs</H2>
            <P className="mb-3">
              In einer Gesellschaft, die von Leistung, Geschwindigkeit und Druck geprägt ist, leiden viele Menschen unter Stresssymptomen wie Zähneknirschen, Rückenschmerzen und Verspannungen. Dagegen hilft dir der Progressive
              Muskelentspannung Onlinekurs. Als betroffene Person bist du in guter Gesellschaft, denn aktuelle Berichte der Krankenkassen beschreiben einen steigenden Trend der eben genannten Stressreaktionen.
            </P>
            <P>
              In deutschen Schlafzimmern wird zudem geknirscht, was die Schienen halten. Das ist nicht nur schlecht für den Zahnschmelz, sondern auch für deinen Rücken! Wenn du dich gerade angesprochen fühlst, haben wir eine super Nachricht
              für dich. Denn all das kann schon bald der Vergangenheit angehören. Denn die Progressive Muskelentspannung ist das Wundermittel gegen Knirschen, Verspannungen und Muskelschmerzen. Was die Progressive Muskelentspannung ist und
              wie du sie für dich nutzt, das erfährst du jetzt.
            </P>
          </div>
        </GridTwo>
      </Container>

      <Cta headOne="Bist Du bereit für" headTwo="Entspannung?" button={<CourseButton buttonText={`${data.kurse.title} buchen`} modalButtonText={`Bestätigen`} dialogTitle="Kontraindikationen" link={data.kurse.link} />} />
      <Container>
        <GridTwo>
          <div className="relative grid grid-cols-1 gap-4 rounded-xl bg-white p-6 text-brand-gray md:grid-cols-1 lg:grid-cols-2">
            <div>
              <H2 className="mb-5 font-display">Was du hier erfährst:</H2>
              <FeatureList
                items={[
                  { text: 'Was ist Progressive Muskelentspannung?' },
                  { text: 'Wirkung der Muskelentspannung' },
                  { text: 'Förderliche Effekte der Progressiven Muskelentspannung' },
                  { text: 'Paradoxon: Entspannung durch Anspannung?' },
                  { text: 'Muskelentspannung leicht gemacht: Eine Anleitung' },
                  { text: 'Progressive Muskelentspannung Onlinekurs' }
                ]}
                className="space-y-5"
              />
            </div>
            <div>
              <GatsbyImage image={getImage(data.bildJohannes)} alt="Autogenes Training - Dein Held Johannes" title="Autogenes Training - Dein Held Johannes" className="rounded-xl" />
            </div>
          </div>
          <div className="rounded-xl bg-white p-6 text-brand-gray">
            <H2 className="mb-5 font-display">Was unsere Absolventen sagen:</H2>
            <TestmonialsSlider />
          </div>
        </GridTwo>
      </Container>

      <Container noMargin className="text-brand-gray">
        <div className="mx-auto max-w-2xl space-y-8">
          <div>
            <H2 className="mb-5 font-display">Kurzübung Muskelentspannung</H2>
            <ImageVideo video="https://res.cloudinary.com/lautenschlager/video/upload/v1642070396/kunden/entspannungshelden/Muskelentspannung_Kurz%C3%BCbung_wrnj9b.mp4" image={data.videoCover} alt="Kurse Muskelentspannungsübung" />
          </div>

          <div>
            <H2 className="mb-5 font-display">Was ist Progressive Muskelentspannung?</H2>
            <P>
              Die Progressive Muskelentspannung ist ein aktives Entspannungsverfahren, das sehr einfach zu erlernen ist. Neben dem Autogenen Training zählt es zu den am besten erforschten Methoden überhaupt und lässt sich wissenschaftlich
              begründen. Das Verfahren wurde 1929 von Edmund Jacobson entwickelt. Er erforschte den Zusammenhang zwischen Muskelspannung und Aktivität des zentralen Nervensystems. Dabei fand er heraus, dass sowohl mentale, als auch
              körperliche Anspannung eine „Verkürzung“ der einzelnen Muskelfasern mit sich bringt, woraufhin er die Progressive Muskelentspannung entwickelte.
            </P>
          </div>

          <div>
            <H2 className="mb-5 font-display">Wirkung der Muskelentspannung</H2>
            <P className="mb-3">
              Die Forschungsergebnisse ergeben, dass mentale und körperliche Anspannung in einem Wirkzusammenhang stehen. Was bedeutet progressive Muskelentspannung? Veereinfacht gesagt bedeutet das: Erlebst du negative Emotionen wie Angst,
              spannen sich deine Muskeln an, dein Körper folgt deinem Geist. Die Progressive Muskelentspannung setzt genau hier an, denn Folgerichtig wird z.B. Angst durch körperliche Spannung begünstigt, die durch das Verfahren ja gesenkt
              wird. Natürlich kann sich dadurch eine unangenehme Wechselwirkung aus negativen Emotionen und körperlichen Beschwerden entwickeln. Denn die gute Nachricht ist, dass du durch die Progressive Muskelentspannung auf beide
              „Spannungssysteme“ Einfluss nehmen kannst. Entspannte Muskeln begünstigen also einen beruhigten Geist.
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Förderliche Effekte der Progressiven Muskelentspannung</H2>
            <FeatureList
              items={[
                { text: 'Muskelspannung wird heruntergesetzt' },
                { text: 'Schultern und Nacken entspannen sich' },
                { text: 'Reduziert nächtliches Knirschen oder Reiben' },
                { text: 'Herzfrequenz und Blutdruck werden gesenkt' },
                { text: 'Atmung wird tiefer und entspannter' },
                { text: 'Regulierung deines Stoffwechsels' },
                { text: 'Förderung von Stoffwechselprozessen' },
                { text: 'Schmerzreduktion insbesondere bei Kopfschmerzen' },
                { text: 'Reduktion von Rückenschmerzen' },
                { text: 'Positive Wirkung bei Ängsten, Panikattacken' },
                { text: 'Reduzierung von psychischen Leiden' },
                { text: 'Sofortige Entspannung bei Anwendung' },
                { text: 'Gesteigerte Konzentration und Frische' }
              ]}
              className="grid grid-cols-1 gap-5 sm:grid-cols-2"
            />
          </div>
          <Cta headOne="Bist Du bereit für" headTwo="Entspannung?" button={<CourseButton buttonText={`Kurs buchen`} modalButtonText={`Bestätigen`} dialogTitle="Kontraindikationen" link={data.kurse.link} />} withoutContainer />
          <div>
            <H2 className="mb-5 font-display">Paradoxon: Entspannung durch Anspannung?</H2>
            <P className="mb-3">
              Die Progressive Muskelentspannung nutzt das Prinzip der Erschöpfung, um deine Muskeln zu entspannen. Bestimmt kennst du das Gefühl von Schwere nach einem Krafttraining. Du bist müde und erschöpft, aber dennoch glücklich. Genau
              dieses Gefühl erzeugt die Progressive Muskelentspannung auch. Die Muskelfasern sind müde und erschlaffen, wodurch sich der Muskel entspannt. Das bedeutet, dass du die Aktivierung gezielter Muskelentspannung, genau diese
              Muskeln entspannen kannst. Das ist besonders hilfreich im Bereich Kiefer- und Mundpartie, sowie für die Hals-Nacken Muskulatur und deutlich effektiver als Dehnen!
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Anleitung zur Progressiven Muskelentspannung</H2>
            <P className="mb-3">
              Das Verfahren Progressive Muskelentspannung zählt zu den einfachsten und wirkungsvollsten Verfahren gegen verspannte Muskeln und Knirschen. Das ursprüngliche Verfahren von Jacobson umfasste 30 Einzelmuskeln, die im Wechsel an
              und entspannt wurden. Verkürzte Versionen ermöglichen es, die Muskelentspannung immer und überall anzuwenden. In unserem Onlinekurs erlernst du das Verfahren mit 16, 7 und 4 Muskelgruppen, bei denen du die Anspannung einzelner
              Muskelgruppen für 7-10 Sekunden hältst und mit deiner Ausatmung wieder löst. Die kürzeste Übung haben wir dir als kleine Anleitung vorbereitet, wenn du möchtest probier sie aus! Solltest du das Verfahren erlernen und von den
              vielen Gesundheitsvorteilen profitieren wollen, buche unseren Progressive Muskelentspannung Onlinekurs.
            </P>
            <dl className="mb-3 space-y-3">
              <Disclosure title="1.	Positionierung und Vorbereitung">
                <P>
                  Stell dich bequem hin. Deine Fuße stehen hüftbreit deine Hände hängen locker neben deinem Körper. Du atmest ruhig und entspannt, nach Möglichkeit so, dass sich deine Bauchdecke mit jeder Einatmung ein Stückchen nach vorne
                  schiebt und mit deiner Ausatmung wieder senkt.
                </P>
              </Disclosure>
              <Disclosure title="2.	Anspannung der Hände">
                <P>
                  Nimm nun deine Hände auf Höhe deines Bauchnabels, so dass du deine Handflächen sehen kannst. Balle sie nun zu Fäusten, lasse dabei deinen Daumen draußen anliegen und gehe so fest in die Anspannung, wie es für dich noch gut
                  erträglich ist.
                </P>
              </Disclosure>
              <Disclosure title="3.	Arme fest an den Körper drücken">
                <P>
                  Während du ruhig weiter atmest, bringst du nun deine geballten Fäuste zu deinen Schultern und drückst deine Arme ganz fest an deinen Körper. Du spürst wie deine Oberarme auch ganz fest werden und konzentrierst dich auf das
                  Gefühl der Anspannung. Achte auch jetzt darauf weiter zu atmen.
                </P>
              </Disclosure>
              <Disclosure title="4.	Schultern nach hinten oben ziehen">
                <P>
                  Gehe nun über zu deiner Schulter-, Nacken und Rückenmuskulatur. Um diese häufig verspannten Muskeln anzuspannen, ziehe deine Schulterblätter nach hinten, ganz so als würdest du mit deinen Schulterblättern eine Nuss knacken
                  wollen, führe die Schultern dann nach oben hin zu deinen Ohren. Halte auch jetzt die Spannung und atme ruhig weiter.
                </P>
              </Disclosure>
              <Disclosure title="5.	Beine und Po fest anspannen">
                <P>
                  Gleich hast du es auch schon geschafft, presse während dein Atem zirkuliert, nun deine Pobacken fest zusammen, strecke deine Beine durch, so dass deine Knie gestreckt sind und kralle deine Zehen in den Boden. Bemerkst du
                  die Wärme und Anstrengung?
                </P>
              </Disclosure>
              <Disclosure title="6.	Grimasse schneiden und lösen">
                <P>
                  Um jetzt noch möglichst viele deiner Gesichtsmuskeln anzustrengen schneide eine lustige Grimasse, verzichte aber darauf deine Zähne aufeinander zu pressen und atme auch jetzt weiter. Halte die Spannung noch für einen
                  kurzen Moment und löse sie dann gleich, mit deiner nächsten Ausatmung auf. Lasse alle Muskeln einfach fallen und wieder ganz locker und weich werden.
                </P>
              </Disclosure>
              <Disclosure title="7.	Körperwahrnehmung">
                <P>
                  Nimm dir nun einen Moment Zeit um in deinen Körper zu fühlen. Spürst du ein leichtes Kribbeln und wohlige Wärme in deinen Händen und Füßen? Bemerkst du dass dein Puls etwas schneller geht und dein Atemrhythmus sich
                  verändert hat? Das sind erste Anzeichen der Entspannung. Du kannst diese Übung jetzt wo du weißt was kommt auch mehrfach wiederholen. Insgesamt solltest du circa 10 Sekunden in der Anspannung verbleiben und immer mit der
                  Ausatmung lösen.
                </P>
              </Disclosure>
            </dl>
          </div>
          <div>
            <H2 className="mb-5 font-display">Jetzt entspannen mit dem Progressiven Muskelentspannung Onlinekurs</H2>
            <P className="mb-3">
              Nun hast du einen Einblick in die Progressive Muskelentspannung bekommen und konntest die positive Wirkung spüren. Doch das war nur Anfang. In unserem bewährten Progressive Muskelentspannung Onlinekurs lernst du das Verfahren
              in 8 Trainingseinheiten kennen und setzt es gezielt dann ein, wann du entspannen möchtest. Die 45 minütigen Videos stehen dir zeit- und ortsunabhängig zur Verfügung, so dass du deinen Kurs in deinem Tempo absolvierst. Mit
              unserer Zertifizierung durch die gesetzlichen Krankenkassen profitierst du zudem durch eine Kostenbeteiligung deiner Krankenkasse zum Angebot: Progressive Muskelentspannung Onlinekurs. Wie hoch die Beteiligung deiner
              Krankenversicherung ausfällt, kannst du hier checken:
            </P>
            <Button to="/kurse/krankenkasse/" text="Krankenkassencheck" />
          </div>
        </div>
      </Container>
      <Cta headOne="Bist Du bereit für" headTwo="Entspannung?" button={<CourseButton buttonText={`${data.kurse.title} buchen`} modalButtonText={`Bestätigen`} dialogTitle="Kontraindikationen" link={data.kurse.link} />} />
      <Container>
        <GridTwo>
          <div>
            <H2 className="mb-5 font-display">Impressionen aus dem Kurs:</H2>
            <GallerySlider images={data.gallery.nodes} totalcount={data.gallery.totalCount} alt="Autogenes Training Onlinekurs" />
          </div>
          <div>
            <H2 className="mb-5 font-display">Häufige Fragen zu der progressiven Muskelentspannung:</H2>
            <dl className="space-y-3">
              {faq.map((faq) => {
                return <FaqItem title={faq.frage} text={parse(faq.text)} key={faq.id} />;
              })}
            </dl>
          </div>
        </GridTwo>
      </Container>
    </Layout>
  );
}

export default Page;

export const query = graphql`
  {
    bildKurs: file(relativePath: { eq: "course-images/progressive-muskelentspannung.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    kurse(slug_slug: { eq: "progressive-muskelentspannung-onlinekurs" }) {
      remoteKurseImage {
        url
        childImageSharp {
          gatsbyImageData
        }
      }
      frequenz
      price
      link
      title
      desc
      features {
        value
        field {
          label
        }
      }
    }

    bildJohannes: file(relativePath: { eq: "pages/kurse/progressive-muskelentspannung/progressive-muskelentspannung_dein-held-johannes.png" }) {
      childImageSharp {
        gatsbyImageData(aspectRatio: 1)
      }
    }

    videoCover: file(relativePath: { eq: "video/muskelentspannung-kurzuebung_cover.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    gallery: allFile(filter: { relativeDirectory: { eq: "pages/kurse/progressive-muskelentspannung/gallery" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1.8)
        }
        id
      }
      totalCount
    }
  }
`;
