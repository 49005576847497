import React from 'react';

interface Props {
  name: string;
  description: string;
  icon: React.ReactNode;
  align?: 'left' | 'center';
  iconAlign?: 'left' | 'top';
}

const Blurb: React.FC<Props> = ({ name, description, icon, align = 'center', iconAlign = 'top' }) => {
  return (
    <div className={`${align === 'center' ? 'text-center' : ''} ${iconAlign === 'left' ? 'flex items-start space-x-6' : ''}`}>
      <div className={`inline-block rounded-xl bg-brand-green p-2 text-white`}>{icon}</div>
      <div>
        <dt>
          <p className={`${iconAlign === 'left' ? '' : 'mt-5'} font-display text-lg font-medium leading-6 text-brand-gray`}>{name}</p>
        </dt>
        <dd className="mt-2 text-base text-gray-500">{description}</dd>
      </div>
    </div>
  );
};

export default Blurb;
