import React from 'react';
import { CheckIcon } from '@heroicons/react/outline';

interface Props {
  items: Item[];
  className?: string;
}

interface Item {
  text: string;
  gray?: boolean;
}

const FeatureList: React.FC<Props> = ({ items, className }) => {
  return (
    <ul className={className ? className : ''}>
      {items.map((feature, i) => {
        return (
          <li className="flex space-x-2 text-left text-sm" key={`fetaure-${feature.text}-${i}`}>
            {feature.gray ? <CheckIcon className="h-5 w-5 flex-shrink-0 text-gray-500" /> : <CheckIcon className="h-5 w-5 flex-shrink-0 text-brand-green-dark" />}
            <span>{feature.text}</span>
          </li>
        );
      })}
    </ul>
  );
};

export default FeatureList;
