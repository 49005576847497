import React from 'react';
import Container from '../common/Container';
import H2 from '../typography/H2';

function Cta({ headOne, headTwo, button, withoutContainer }) {
  if (withoutContainer) {
    return (
      <div className="rounded-xl bg-white p-6 shadow-xl lg:flex lg:items-center lg:justify-between">
        <H2 as="p" className="font-display">
          <span className="block">{headOne} </span>
          <span className="block text-brand-green-dark">{headTwo}</span>
        </H2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">{button}</div>
        </div>
      </div>
    );
  }
  return (
    <div className="bg-green">
      <Container>
        <div className="rounded-xl bg-white p-6 shadow-xl lg:flex lg:items-center lg:justify-between">
          <H2 as="p" className="font-display">
            <span className="block">{headOne} </span>
            <span className="block text-brand-green-dark">{headTwo}</span>
          </H2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">{button}</div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Cta;
