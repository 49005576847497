import { ArrowCircleLeftIcon, ArrowCircleRightIcon } from '@heroicons/react/solid';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import React from 'react';

function GallerySlider({ images, totalcount, className, alt }) {
  return (
    <CarouselProvider visibleSlides={1} totalSlides={totalcount} step={1} naturalSlideWidth={1920} naturalSlideHeight={1080} infinite isIntrinsicHeight className={`${className ? className : ''} relative rounded-xl`}>
      <Slider>
        {images.map((image, i) => {
          return (
            <Slide index={i} key={image.id}>
              <GatsbyImage image={getImage(image)} className="w-full rounded-xl" alt={`${alt} - Gallerybild - ${i}`} title={`${alt} - Gallerybild - ${i}`} />
            </Slide>
          );
        })}
      </Slider>
      <div className="absolute inset-0 flex h-full items-center justify-between px-8">
        <ButtonBack aria-label="Vorheriges Bild">
          <ArrowCircleLeftIcon className="h-12 w-12 text-brand-gray opacity-70 transition-colors duration-300 hover:text-white" />
        </ButtonBack>
        <ButtonNext aria-label="Nächstes Bild" className=" focus:outline-none focus:ring-1 focus:ring-black focus:ring-offset-1">
          <ArrowCircleRightIcon className="h-12 w-12 text-brand-gray opacity-70 transition-colors duration-300 hover:text-white" />
        </ButtonNext>
      </div>
    </CarouselProvider>
  );
}

export default GallerySlider;
