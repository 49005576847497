/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Switch, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { graphql, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import React, { Fragment, useState } from 'react';
import ModalKontradiktion from '../common/ModalKontradiktion';
import P from '../typography/P';
import Button from './Button';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CourseButton = ({ buttonText, buttonClass, modalButtonText, link, dialogTitle, children }) => {
  const [open, setOpen] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const data = useStaticQuery(
    graphql`
      query {
        kontradiktionen: rechtliches(slug_slug: { eq: "kontraindikationen" }) {
          text
          title
        }
        haftungsausschluss: rechtliches(slug_slug: { eq: "haftungsausschluss" }) {
          text
          title
        }
        kommunikation: rechtliches(slug_slug: { eq: "kommunikation" }) {
          text
          title
        }
        kontradiktionen: rechtliches(slug_slug: { eq: "kontraindikationen" }) {
          text
          title
        }
        datenschutz: rechtliches(slug_slug: { eq: "datenschutz" }) {
          text
          title
        }
        foerderung: rechtliches(slug_slug: { eq: "foerderung-durch-deine-krankenkasse" }) {
          text
          title
        }
      }
    `
  );

  return (
    <>
      <Button text={buttonText} onClick={() => setOpen(true)} className={buttonClass} />
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 z-10 overflow-y-auto" open={open} onClose={setOpen}>
          <div className="flex min-h-screen items-start justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 sm:scale-95"
              enterTo="opacity-100 tsm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 sm:scale-100"
              leaveTo="opacity-0 sm:scale-95"
            >
              <div className="inline-block overflow-hidden rounded-xl relative bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button type="button" className="focus:outline-none bg-white text-gray-400 hover:text-gray-500 focus:ring-0 focus:ring-brand focus:ring-offset-0" onClick={() => setOpen(false)}>
                    <span className="sr-only">Schließen</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div>
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-brand-gray">
                      {dialogTitle}
                    </Dialog.Title>
                    <div className="mt-2 flex space-x-3">
                      <div>
                        <Switch
                          checked={enabled}
                          onChange={setEnabled}
                          className={classNames(
                            enabled ? 'bg-brand-green-dark' : 'bg-gray-200',
                            'focus:outline-none relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:ring-2 focus:ring-brand-green-dark focus:ring-offset-2'
                          )}
                        >
                          <span className="sr-only">Kontraindikationen akzeptieren</span>
                          <span className={classNames(enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out')}>
                            <span
                              className={classNames(enabled ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity')}
                              aria-hidden="true"
                            >
                              <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </span>
                            <span
                              className={classNames(enabled ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity')}
                              aria-hidden="true"
                            >
                              <svg className="h-3 w-3 text-brand-green-dark" fill="currentColor" viewBox="0 0 12 12">
                                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                              </svg>
                            </span>
                          </span>
                        </Switch>
                      </div>
                      <div>
                        <P>
                          Hiermit bestätige Ich, dass keine <ModalKontradiktion text={parse(data.kontradiktionen.text)}>{data.kontradiktionen.title}</ModalKontradiktion> vorliegen. Die Texte "
                          <ModalKontradiktion text={parse(data.haftungsausschluss.text)}>{data.haftungsausschluss.title}</ModalKontradiktion>
                          ", "<ModalKontradiktion text={parse(data.kommunikation.text)}>{data.kommunikation.title}</ModalKontradiktion>" ,"
                          <ModalKontradiktion text={parse(data.foerderung.text)}>{data.foerderung.title}</ModalKontradiktion>" und "<ModalKontradiktion text={parse(data.datenschutz.text)}>{data.datenschutz.title}</ModalKontradiktion>" habe
                          ich gelesen und verstanden.
                        </P>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 flex flex-col-reverse gap-2 sm:mt-4 sm:flex-row-reverse">
                  <Button text={modalButtonText} href={link} className={buttonClass} disabled={!enabled} target="_blank" />

                  <Button text="Abbrechen" onClick={() => setOpen(false)} className={buttonClass} type="white" small />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default CourseButton;
