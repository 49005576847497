// Packages
import React from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

// Components
import Grid from '../common/Grid';
import H1 from '../typography/H1';
import Container from '../common/Container';
import Divider from './Divider';

interface Props {
  title?: string;
  children: React.ReactNode;
  image?: IGatsbyImageData;
  imageMobile?: IGatsbyImageData;
  imageText?: string;
  classNameImage?: string;
  wrapperClass?: string;
  divider?: boolean;
}

const PageHeader: React.FC<Props> = ({ title, children, image, imageMobile, imageText, classNameImage, wrapperClass, divider }) => {
  const imageGatsby = image ? getImage(image) : null;
  const imageGatsbyMobile = imageMobile ? getImage(imageMobile) : null;

  return (
    <div className={`overflow-hidden bg-brand-green/50`}>
      <div className={wrapperClass ? wrapperClass : ''}>
        <Container>
          <Grid cols={2} items="center">
            <div className="text-brand-gray">
              <H1 className="mb-5 font-bold">{title}</H1>
              {children}
            </div>
            {imageGatsby && (
              <div className={`text-center`}>
                <div className={`${imageGatsbyMobile !== undefined ? '' : 'hidden md:block'}`}>
                  <GatsbyImage image={imageGatsby} alt={`${title} - Symbolbild`} title={`${title} - Symbolbild`} className={`mx-auto rounded-xl ${classNameImage ? classNameImage : ''}`} />
                </div>

                {imageGatsbyMobile && (
                  <div className={`lg:hidden`}>
                    <GatsbyImage image={imageGatsbyMobile} alt={`${title} - Symbolbild`} title={`${title} - Symbolbild`} className={`mx-auto ${classNameImage ? classNameImage : ''} hidden`} />
                  </div>
                )}
                {imageText && <p className={`mx-auto mt-2 max-w-md text-sm text-brand-gray`}>{imageText}</p>}
              </div>
            )}
          </Grid>
        </Container>
      </div>
      {divider ? <Divider className={`rotate-180 text-gray-50`} /> : null}
    </div>
  );
};

export default PageHeader;
