import { PlayIcon } from '@heroicons/react/solid';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useRef, useState } from 'react';

interface Props {
  video: string;
  image: IGatsbyImageData;
  alt: string;
  smallIcon?: boolean;
}

const ImageVideo: React.FC<Props> = ({ video, image, alt, smallIcon }) => {
  const [videoActice, setIsActive] = useState(false);

  const videoTag: React.RefObject<HTMLVideoElement> = useRef(null);
  const imageCover = getImage(image);

  const handleVideo = () => {
    setIsActive(true);
    videoTag?.current?.play();
  };

  return (
    <div className="mx-auto pb-0">
      <button className={`relative cursor-pointer ${videoActice ? 'hidden' : 'block'}`} onKeyPress={handleVideo} onClick={handleVideo}>
        <div className={`absolute top-0 left-0 z-10 flex h-full w-full rounded-xl transition-colors duration-300 hover:bg-brand-green hover:opacity-80 ${smallIcon ? 'items-end justify-end pb-3 pr-3' : 'items-center justify-center'}`}>
          <PlayIcon className={`"text-white hover:opacity-50 ${smallIcon ? 'h-8' : 'h-16 md:h-32'}`} />
        </div>
        {imageCover ? <GatsbyImage image={imageCover} alt={alt} className="rounded-xl align-top" /> : null}
      </button>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video controls ref={videoTag} className={`rounded-xl ${videoActice ? 'block' : 'hidden'}`}>
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
};

export default ImageVideo;
