import { graphql, useStaticQuery } from 'gatsby';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import React from 'react';

const TestmonialSlider = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      allTestmonial {
        nodes {
          text
          name
          id
          bild {
            path
          }
        }
        totalCount
      }
    }
  `);

  return (
    <CarouselProvider visibleSlides={1} totalSlides={data.allTestmonial.totalCount} step={1} naturalSlideWidth={320} naturalSlideHeight={100} infinite isIntrinsicHeight className={`${className ? className : ''}`}>
      <Slider>
        {data.allTestmonial.nodes.reverse().map((review, i) => {
          return (
            <Slide index={i} key={review.id}>
              <figure className="cursor-grab">
                <blockquote className="mb-3 leading-relaxed">{review.text.split('').length > 1000 ? `${review.text.slice(0, 1000)}...` : review.text}</blockquote>
                <figcaption className="flex items-center justify-end">
                  <img className="mr-3 inline-block h-16 w-16 rounded-full" src={`https://cms.entspannungshelden.de/${review.bild.path}`} alt={review.name} />
                  <cite className="font-display font-bold">{review.name ? review.name : 'Anonym'}</cite>
                </figcaption>
              </figure>
            </Slide>
          );
        })}
      </Slider>
      <div className="mt-3 flex justify-end space-x-3">
        <ButtonBack
          aria-label="Vorherige Bewertung"
          className="focus:outline-none inline-flex items-center justify-center rounded-xl bg-brand-green/50 px-5 py-2 text-brand-gray transition-colors duration-300 hover:bg-brand-green-dark hover:text-white focus:ring-1 focus:ring-black focus:ring-offset-1"
        >
          Vorherige
        </ButtonBack>
        <ButtonNext
          aria-label="Nächste Bewertung"
          className="focus:outline-none inline-flex items-center justify-center rounded-xl bg-brand-green/50 px-5 py-2 text-brand-gray transition-colors duration-300 hover:bg-brand-green-dark hover:text-white focus:ring-1 focus:ring-black focus:ring-offset-1"
        >
          Nächste
        </ButtonNext>
      </div>
    </CarouselProvider>
  );
};

export default TestmonialSlider;
